<template>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>Completar Cadastro</v-card-title>
        <v-card-text>
          <v-form ref="formInfo" v-model="valid" v-if="open=='info'">
            <!-- Tipo de Cadastro -->
            <v-select
              v-model="form.info.user_type"
              :items="['individual', 'business']"
              label="Tipo de Cadastro"
              required
              @change="toggleUserType"
            ></v-select>
  
            <!-- Campos Comuns -->
            <v-row>
              <v-col cols="6">
                <v-text-field label="Nome" v-model="form.info.first_name" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Sobrenome" v-model="form.info.last_name" required></v-text-field>
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="6">
                <v-text-field label="Email" v-model="form.info.email" required type="email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Telefone" v-model="form.info.phone_number" required></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field label="CPF" v-model="form.info.taxpayer_id" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Data de Nascimento" v-model="form.info.birthdate" type="date" required></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="6">
                <v-text-field label="Nome Fantasia" v-model="form.info.statement_descriptor" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Receita" v-model="form.info.revenue" required></v-text-field>
              </v-col>
            </v-row>
  
            <!-- Campos Adicionais com CEP -->
            <v-row>
              <v-col cols="6">
                <v-text-field label="CEP" v-model="form.info.postal_code" @change="fetchAddress"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="this.addressField.loader"
                ></v-progress-circular>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="6">
                <v-text-field label="Bairro" v-model="form.info.neighborhood" :readonly="addressField.block"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Rua" v-model="form.info.address_line1" :readonly="addressField.block"></v-text-field>
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="6">
                <v-text-field label="Cidade" v-model="form.info.city" :readonly="addressField.block"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Estado" v-model="form.info.state" :readonly="addressField.block"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field label="Número" v-model="form.info.address_line2"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Complemento" v-model="form.info.address_line3"></v-text-field>
              </v-col>
            </v-row>
  
            <!-- Campos Exclusivos para Empresa -->
            <v-expand-transition>
              <div v-if="form.info.user_type === 'business'">
                <v-text-field label="Nome da Empresa" v-model="form.info.business_name"></v-text-field>
                <v-text-field label="Descrição da Empresa" v-model="form.info.description"></v-text-field>
                <v-text-field label="CNPJ" v-model="form.info.ein"></v-text-field>
                <v-text-field label="Data de Abertura" v-model="form.info.business_opening_date" type="date" required></v-text-field>
              </div>
            </v-expand-transition>
          </v-form>
          <v-form ref="formBank" v-model="valid" v-if="open=='bank'">
            <h3>Adicione uma conta bancária de sua titularidade</h3>
            <v-text-field label="Banco" v-model="form.bank.bankNumber"></v-text-field>
            <v-text-field label="Agência (sem DV)" v-model="form.bank.agency"></v-text-field>
            <v-text-field label="Número da Conta (com DV)" v-model="form.bank.accountNumber"></v-text-field>
            <v-select label="Tipo da Conta" v-model="form.bank.accountType" :items="[{text: 'Conta Corrente', value: 'checking'}, {text: 'Conta Poupança', value: 'savings'}]"></v-select>
            </v-form>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submitForm(open)">Salvar</v-btn>
          <v-btn @click="close">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: "CompleteAccount",
    props: ["aplication", "open"],
    data() {
      return {
        dialog: false,
        valid: true,
        form: {
          info: {
            user_type: 'individual',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            taxpayer_id: '',
            birthdate: '',
            statement_descriptor: '',
            revenue: '',
            postal_code: '',
            neighborhood: '',
            address_line1: '',
            address_line2: '',
            address_line3: '',
            city: '',
            state: '',
            country_code: 'BR',
            business_name: '',
            description: '',
            business_phone: '',
            business_opening_date: '',
            ein: ''
          },
          bank: {
            bankNumber: '',
            agency: '',
            accountNumber: '',
            accountType: ''
          }
        },
        addressField: {
            block: false,
            loader: false
        }
      };
    },
    methods: {
      async post(endpoint = "/", data) {
        try {
          this.$emit('setLoading',true);
          const response = await axios({
            url: this.aplication.baseUrlApi + endpoint,
            method: "POST",
            headers: {
                Authorization: this.aplication.authToken,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          });
          this.$emit('setLoading',false);
          this.$emit("reactRequest", response.data);
          return response.data;
        } catch (error) {
          this.$emit('setLoading',false);
          if (error.response &&error.response.data &&error.response.data.errors) {
            this.$emit("reactRequest", error.response.data);
          }
        }
      },
      toggleUserType() {
        if (this.form.info.user_type === 'individual') {
          this.form.info.business_name = '';
          this.form.info.ein = '';
        }
      },
      fetchAddress() {
        this.addressField.block = false;
        this.addressField.loader = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.info.postal_code}/json/`)
          .then((response) => {
            if (response.data) {
              this.form.info.address_line1 = response.data.logradouro || '';
              this.form.info.neighborhood = response.data.bairro || '';
              this.form.info.city = response.data.localidade || '';
              this.form.info.state = response.data.uf || '';
              this.addressField.block = true;
            }
            this.addressField.loader = false;
          })
          .catch(() => {
            this.form.info.address_line1 = '';
            this.form.info.neighborhood = '';
            this.form.info.city = '';
            this.form.info.state = '';
            this.addressField.block = false;
            this.addressField.loader = false;
          });
      },
      async submitForm(open) {
        let response;
        if(open=='info'){
          if (this.$refs.formInfo.validate()) {
            response = await this.post('/app/account/complete', this.form.info);
          }
        }
        if(open=='bank'){
          if (this.$refs.formBank.validate()) {
            response = await this.post('/app/account/complete/bank', this.form.bank);
          }
        }
        console.log(response);
      },
      close(){
        this.dialog = false;
        this.$emit('close');
      }
    },
    mounted(){
        if(this.open && this.open!=null) this.dialog = true;
    }
  };
  </script>
  
  <style scoped>
  .v-text-field {
    margin-bottom: 20px;
  }
  </style>
  